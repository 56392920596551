import { useEffect } from "react";
import { useLogout } from "./useLogout";

export default function useLogoutWhenUnauthorized(shouldLogout: boolean, error: any) {
  const { logout } = useLogout();

  useEffect(
    function handleAuthenticationError() {
      if (shouldLogout && error && error.response?.status === 401) {
        logout().finally(() => {
          window.location.href = "/welcome";
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [shouldLogout, error]
  );
}
