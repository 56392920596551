import axios from "axios";
import { getBonusxApiConfigV2 } from "../../../../common/bonusxApiConfig";
import { Mapper } from "../../../../utils/mapper";
import { IApiClientService } from "../../../ApiClient/ApiClientService.interface";
import AxiosApiClientInstance from "../../../AxiosApiClientInstance/AxiosApiClientInstance";
import { ExternalUrlSessionDto } from "../../dtos/external-url-session.dto";
import { SelfPortalPageDto } from "../../dtos/self-portal-page.dto";
import { UserSubscriptionDto } from "../../dtos/user-subscription.dto";
import { IUserSubscriptionDatasource } from "./user-subscription.datasource.interface";

export class UserSubscriptionDataSource implements IUserSubscriptionDatasource {
  private readonly _OPTIONS = getBonusxApiConfigV2();
  private readonly _BASE_URL = "/subscriptions";

  constructor(private readonly _client: IApiClientService = new AxiosApiClientInstance(axios)) {}

  async getCurrentUserSubscription(): Promise<UserSubscriptionDto> {
    const { data } = await this._client.get(`${this._BASE_URL}/account`, this._OPTIONS);

    if (!data) {
      throw new Error("User subscription not available");
    }

    const mappedDto = Mapper.mapData(UserSubscriptionDto, data);

    return mappedDto;
  }

  async getPricingPage(redirectUrl: string): Promise<ExternalUrlSessionDto> {
    const { data } = await this._client.get(`${this._BASE_URL}/pricing-page?redirectUrl=${redirectUrl}`, this._OPTIONS);

    if (!data) {
      throw new Error("Pricing page not available");
    }

    const mappedDto = Mapper.mapData(ExternalUrlSessionDto, data);

    return mappedDto;
  }

  async getSelfPortalPage(): Promise<SelfPortalPageDto> {
    const { data } = await this._client.get(`${this._BASE_URL}/self-portal-page`, this._OPTIONS);

    if (!data) {
      throw new Error("Self portal page not available");
    }

    const mappedDto = Mapper.mapData(SelfPortalPageDto, data);

    return mappedDto;
  }
}
