import { ExternalUrlSession } from "./entities/external-url-session.entity";
import { BONUSX_SUBSCRIPTION_TIERS } from "./enums/bonusx-subscription-tiers.enum";
import { UserSubscriptionRepository } from "./repositories/current-user-subscription/user-subscription.repository";
import { IUserSubscriptionRepository } from "./repositories/current-user-subscription/user-subscription.repository.interface";
import { SpecificUserSubscriptionRepository } from "./repositories/specific-user-subscription/specific-user-subscription.repository";
import { ISpecificUserSubscriptionRepository } from "./repositories/specific-user-subscription/specific-user-subscription.repository.interface";
import { IUserSubscriptionService } from "./user-subscription.service.interface";

export class UserSubscriptionService implements IUserSubscriptionService {
  constructor(
    private readonly _repository: IUserSubscriptionRepository = new UserSubscriptionRepository(),
    private readonly _adminRepository: ISpecificUserSubscriptionRepository = new SpecificUserSubscriptionRepository()
  ) {}

  async getCurrentUserSubscriptionTier(): Promise<BONUSX_SUBSCRIPTION_TIERS> {
    const defaultTier = BONUSX_SUBSCRIPTION_TIERS.FREE;

    try {
      const subscription = await this._repository.getCurrentUserSubscription();
      return subscription.subscriptionTier ?? defaultTier;
    } catch (error) {
      return defaultTier;
    }
  }

  async getSpecificUserSubscriptionTier(citizenId: number): Promise<BONUSX_SUBSCRIPTION_TIERS | undefined> {
    try {
      const subscription = await this._adminRepository.getSpecificUserSubscription(citizenId);
      return subscription?.subscriptionTier;
    } catch (error) {
      return undefined;
    }
  }

  async getPricingPage(redirectUrl: string): Promise<ExternalUrlSession> {
    const externalUrlSession = this._repository.getPricingPage(redirectUrl);

    return externalUrlSession;
  }

  async getSelfPortalPage(): Promise<string> {
    const selfPortalPage = await this._repository.getSelfPortalPage();

    return selfPortalPage?.url;
  }
}
