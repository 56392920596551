import { Expose } from "class-transformer";
import { BONUSX_SUBSCRIPTION_TIERS } from "../enums/bonusx-subscription-tiers.enum";

export class UserSubscriptionDto {
  @Expose()
  subscriptionId!: string;

  @Expose()
  subscriptionTier!: BONUSX_SUBSCRIPTION_TIERS;
}
