import { Ability } from "@casl/ability";
import axios from "axios";
import { AuthenticationApi } from "bonusx-api-auth-manager";
import { useAtomValue, useSetAtom } from "jotai";
import { authApiConfig_v1 } from "../common/env";
import { BonusXAbility } from "../interfaces/BonusXAbility";
import { AbilityRulesStore } from "../stores/abilityRulesStore";

export function useAbility(): BonusXAbility {
  const rules = useAtomValue(AbilityRulesStore);
  return new Ability(rules);
}

export function useAbilityRules() {
  const setAbility = useSetAtom(AbilityRulesStore);

  const getAbilityRules = async () => {
    const authenticationApi = new AuthenticationApi(authApiConfig_v1, undefined, axios);
    const { data } = await authenticationApi.getAbilityRules();
    return setAbility(data);
  };

  return { getAbilityRules };
}
