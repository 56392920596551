import useSWR from "swr";
import { swrBaseConfig } from "../../common/swrBaseConfig";
import { BONUSX_SUBSCRIPTION_TIERS } from "../../services/user-subscription/enums/bonusx-subscription-tiers.enum";
import { userSubscriptionService } from "../../services/user-subscription/user-subscription.instance";
import { getTranslationKeyForSubscriptionTier } from "./use-current-user-subscription-tier.functions";
import { UseCurrentUserSubscriptionTierOutput } from "./use-current-user-subscription-tier.interface";

const USER_SUBSCRIPTION_TIER_KEY = "current-user-subscription-tier";
const USER_SUBSCRIPTION_TIER_CACHE_SECONDS = 2;
const USER_SUBSCRIPTION_TIER_CACHE_MILLISECONDS = USER_SUBSCRIPTION_TIER_CACHE_SECONDS * 1000;

export function useCurrentUserSubscriptionTier(): UseCurrentUserSubscriptionTierOutput {
  const fetcherFunction = userSubscriptionService.getCurrentUserSubscriptionTier.bind(userSubscriptionService);
  const currentUserTierData = useSWR<BONUSX_SUBSCRIPTION_TIERS>(USER_SUBSCRIPTION_TIER_KEY, fetcherFunction, {
    ...swrBaseConfig,
    dedupingInterval: USER_SUBSCRIPTION_TIER_CACHE_MILLISECONDS,
    suspense: true,
  });

  const currentUserTierOrFree = currentUserTierData.data ?? BONUSX_SUBSCRIPTION_TIERS.FREE;
  const isLoading = currentUserTierData.isValidating;
  const revalidate = currentUserTierData.revalidate;

  const currentUserTierTranslationKey = getTranslationKeyForSubscriptionTier(currentUserTierOrFree);

  return {
    currentUserTier: currentUserTierOrFree,
    translationKeyForTier: currentUserTierTranslationKey,
    isLoading,
    revalidate,
  };
}
